
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IAmount,
  IAccountingInAYear,
  IAccountingInAYearItem,
  IAccountingYears,
} from "@/models/IPropertyAccounting";
import { HousfyInputSelect } from "housfy-ui-lib";
import IOption from "@/models/IOption";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import Money from "@/models/Money";
import BarChartVertical from "@/components/charts/barChartVertical.vue";
import MyRentalsConfig from "@/services/MyRentalsConfig";

@Component({
  components: {
    HousfyInputSelect,
    HousfySvg,
    BarChartVertical,
  },
})
export default class AccountingMainSection extends Vue {
  @Prop({ type: Number, required: true })
  accountingYear!: number;

  @Prop({ type: Object, required: true })
  accountingInAYear!: IAccountingInAYear;

  @Prop({ type: Array, required: true })
  accountingYears!: IAccountingYears["items"];

  get accountingYearsOptions(): IOption<string>[] {
    const options: IOption<string>[] = [];
    this.accountingYears?.forEach((year: string) => {
      options.push({ label: year, value: year });
    });
    return options;
  }

  get currentYearBalance(): IAmount {
    return {
      amount:
        this.accountingInAYear?.amountOfIncome.amount +
        this.accountingInAYear?.amountOfCharges.amount,
      currency: this.accountingInAYear?.amountOfIncome?.currency || "EUR",
    };
  }

  get accountingInAYearItemsSortedByMonthAsc(): IAccountingInAYearItem[] {
    const items = [...this.accountingInAYear?.items];
    return items?.sort((a, b) => a.month - b.month) || [];
  }

  get chartConfig() {
    const data = [...this.accountingInAYearItemsSortedByMonthAsc];

    const months: string[] = [];
    const amounts: number[] = [];

    for (let i = 1; i <= 12; i++) {
      months.push(this.getMonthShortName(i).toUpperCase());
    }

    data.forEach((item) => {
      amounts[item.month - 1] = item.totalAmount.amount;
    });

    const chartData = {
      labels: months,
      datasets: [
        {
          backgroundColor: ["#11AEFA"],
          data: amounts,
        },
      ],
    };
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animationSteps: 100,
      animationEasing: "easeOutBounce",
      animation: {
        animateRotate: true,
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      datasets: {
        bar: {
          borderWidth: 0,
          borderRadius: 5,
        },
      },
      barThickness: 4,
      scales: {
        x: {
          offset: true,
          grid: {
            display: false,
            borderColor: "#F4F8F4",
            drawBorder: true,
            color: "#F4F8F4",
          },
          ticks: {
            color: "#A3BDBF",
          },
        },
        y: {
          offset: false,
          beginAtZero: true,
          grid: {
            drawBorder: false,
            color: "#F4F8F4",
          },
          ticks: {
            color: "#A3BDBF",
            interval: 1000,
            maxTicksLimit: 5,
            padding: 21,
            callback: function (value: number) {
              const number = new Money(value / 100)
                .withOptions({
                  withCents: false,
                })
                .format(true);

              return +number > 1000 ? +number / 1000 + "mil €" : number + " €";
            },
          },
        },
      },
    };

    return { chartData, chartOptions };
  }

  getMonthShortName(month: number): string {
    const date = new Date();
    date.setMonth(month - 1);
    const options = { month: "short" };

    return date.toLocaleString(MyRentalsConfig.country(), options);
  }

  formatNumber(
    number: number,
    withCurrency = false,
    currency?: string
  ): string {
    if (isNaN(number)) return "";
    return new Money(number / 100)
      .withOptions({
        withCents: true,
        currency,
      })
      .format(!withCurrency);
  }
}
